<template>
    <div>
        <el-drawer
            title="操作记录"
            :size="500"
            :visible.sync="drawerVisible"
            ref="drawer"
            direction="rtl">
            <div class="content">
                <div class="content-layout a-flex-1">
                    <el-timeline>
                        <el-timeline-item
                            color="#B9B9B9"
                            v-for="(item, index) in tableData"
                            :key="index"
                            :timestamp="item.createTime">
                            <div>
                                <span class="block a-c-666" style="padding-bottom: 12px">{{ item.name }} {{ item.phone }}</span>
                                <span style="line-height: 20px">{{ item.content }}</span>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                    <div style="width: 100%;height:100%" class="a-flex-rcc" v-if="!tableData.length">
                        <el-empty description="暂无记录"></el-empty>
                    </div>
                </div>
                <div class="a-flex-rsbc">
                    <el-button class="a-flex-1" @click="$refs.drawer.closeDrawer()">关闭</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import util from '../../../utils/util.js'
    export default {
        data() {
            return {
                util: util,
                drawerVisible: false,
                tableData: [],
                recordInfo: ''
            };
        },
        created () {

        },
        methods:{
            getRecordList () {
                let statusDic = {
                    0: '禁用',
                    1: '启用'
                }
                this.$Axios._post({
                    url: this.$Config.apiUrl.getWhiteOperateRecord,
                    params: {
                        id: this.recordInfo.id
                    },
                }).then(({data})=>{
                    let list = data.filter(item=>{
                        return item.oldRecord != item.newRecord
                    })
                    this.tableData = list.map(item=>{
                        let content = ''
                        try{
                            let oldRecord = JSON.parse(item.oldRecord)
                            let newRecord = JSON.parse(item.newRecord)
                            content += `用户 ${newRecord.mobile} 的`
                            if(oldRecord.timeType != newRecord.timeType) { //有效期类型改变
                                if(newRecord.timeType == 0) {
                                    content += `时间从【${util.initDic(oldRecord.timeType,'activityPeriod')}】修改为【${util.initDic(newRecord.timeType,'activityPeriod')}】：【${newRecord.startTime}至${newRecord.endTime}】；`
                                }else{
                                    content += `时间从【${util.initDic(oldRecord.timeType,'activityPeriod')}】：【${oldRecord.startTime}至${oldRecord.endTime}】修改为【${util.initDic(newRecord.timeType,'activityPeriod')}】；`
                                }
                            }
                            if(
                                oldRecord.timeType == newRecord.timeType && 
                                newRecord.timeType == 0 &&
                                ( newRecord.startTime != oldRecord.startTime || newRecord.endTime != oldRecord.endTime )
                            ) { //有效类型未改变，但是类型为短期，且时间改变
                                content += `时间从【${util.initDic(oldRecord.timeType,'activityPeriod')}】：【${oldRecord.startTime}至${oldRecord.endTime}】修改为【${util.initDic(newRecord.timeType,'activityPeriod')}】：【${newRecord.startTime}至${newRecord.endTime}】；`
                            }
                            
                            // 状态修改
                            if(oldRecord.status != newRecord.status) {
                                content += `状态从【${statusDic[oldRecord.status]}】修改为【${statusDic[newRecord.status]}】；`
                            }
                            // 受影响范围
                            if(JSON.stringify(oldRecord.scopeValidList) != JSON.stringify(newRecord.scopeValidList)) {
                                let oldScopeValidList = oldRecord.scopeValidList.map(ite=>{
                                    return ite.name
                                })
                                let newScopeValidList = newRecord.scopeValidList.map(ite=>{
                                    return ite.name
                                })
                                let oldScopeValidListText = oldScopeValidList.toString().replaceAll(',','、')
                                let newScopeValidListText = newScopeValidList.toString().replaceAll(',','、')
                                content += `受影响范围从【${oldScopeValidListText}】修改为【${newScopeValidListText}】；`
                            }
                        }catch(e){
                            //TODO handle the exception
                        }
                        return {
                            ...item,
                            name: '操作人：'+item.operateMobile,
                            createTime: item.operateTime,
                            content: content
                        }
                    })
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .content{
        padding: 0 24px 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        height: 100%;
        .content-layout{
            overflow-y: scroll;
            margin-bottom: 24px;
        }
        .content-layout::-webkit-scrollbar {
            /*width: 0;宽度为0隐藏*/
            width: 0 !important;
            height: 0 !important;
        }
        /deep/ .el-timeline{
            padding: 12px 0 0 6px !important;
        }
        /deep/ .el-timeline-item__tail{
            top: 16px !important;
            bottom: 4px !important;
            height: auto !important;
        }
        /deep/ .el-timeline-item{
            padding-bottom: 30px !important;
        }
        .dot{
            width: 15px;
            height: 15px;
            background: #B9B9B9;
            border-radius: 50%;
            border: 3px solid #ffffff;
        }
    }
</style>