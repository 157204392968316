<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">用户黑名单/白名单列表</span>
            <div class="a-flex-rfsc">
                <el-button icon="el-icon-plus" type="danger" class="s-btn-add" @click="addUser(2)">黑名单</el-button>
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="addUser(1)">白名单</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input-num label="用户手机号" v-model="pageParam.params.mobile" />
                <le-select-local-search label="类型" v-model="pageParam.params.type" :options="optionsType" />
            </le-search-form>
            <le-pagview ref="userBlackPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.userBlackTable">
                <el-table ref="userBlackTable" :data="tableData" :highlight-current-row="true" style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column prop="userId" label="用户ID" min-width="120"></el-table-column>
                    <el-table-column prop="mobile" label="用户手机号" min-width="120"></el-table-column>
                    <el-table-column prop="mobile" label="受影响范围" min-width="120">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs">
                                <span class="stationTag a-w-100" v-for="(item,index) in row.scopeValidList" :key="index">{{ item.name }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.type | initDic(optionsType) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="时效" min-width="140">
                        <template slot-scope="{ row }">
                            <span v-if="row.timeType == 0">{{ row.startTime || '-' }} 至 {{ row.endTime || '-' }}</span>
                            <span v-else>长期</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="启用/禁用" min-width="120">
                        <template slot-scope="scope">
                            <div class="a-flex-rfsc">
                                <el-switch
                                    @change="changeStatus(scope.row,scope.$index)"
                                    v-model="scope.row.status"
                                    :inactive-value="0"
                                    :active-value="1"
                                    active-color="#409EFF"
                                    inactive-color="#F56C6C">
                                </el-switch>
                                <span class="a-mlr-12" :class="scope.row.status?'a-c-blue':'a-c-error'">{{ scope.row.status?'已启用':'已禁用' }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="操作人" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.operateMobile || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="操作时间" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.operateTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="备注" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.remark || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" class="a-flex-rcc" width="100">
                        <template slot-scope="{ row }">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/edit.png" class="a-wh-16" @click="editUser(row)"/>
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="操作记录" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="operateRecord(row)"/>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <black-or-white-record ref="blackOrWhite"></black-or-white-record>
    </div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
import util from '../../utils/util.js'
import BlackOrWhiteRecord from './child/blackOrWhiteRecord.vue';
export default {
    components: {
        BlackOrWhiteRecord
    },
    data() {
        return {
            util: util,
            tableData: [],
            pageParam: {
                url: this.$Config.apiUrl.userWhiteOrBlackPage,
                method: 'post',
                params: {
                    mobile: '',
					type: '',
                },
                freshCtrl: 1,
            },
            optionsType: [],
        }
    },
    computed: {
        ...mapState({
            company: state => state.company.company
        })
    },
    mounted() { 
        this.$getDic('whiteType','select').then(res=>{ this.optionsType = res; })
    },
    methods: {
        //获取用户列表
        setTableData(data) {
            this.tableData = data
        },
        handlerRest() {
            this.pageParam.params = {
                mobile: '',
                userId: '',
                cardNo: '',
                createTimeStart: '',
                createTimeEnd: ''
            }
            this.handlerSearch()
        },

        handlerSearch() {
            this.$refs['userBlackPage'].pageNum = 1
            this.pageParam.freshCtrl++
        },

        addUser (type) {
            this.$router.push({ 
                path: '/userMGT/userMGT-whiteOrBlack-edit',
                query: {
                    type: type
                }
            });
        },

        editUser (datas) {
            this.$router.push({ 
                path: '/userMGT/userMGT-whiteOrBlack-edit',
                query: {
                    type: datas.type,
                    id: datas.id
                }
            });
        },

        changeStatus (datas, index) {
            let content = datas.status == 1 ? '是否启用该用户黑白名单状态？' : '是否禁用该用户黑白名单状态？'
            this.$confirm(content, '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(_=>{
                this.$Axios._post({
                    url: this.$Config.apiUrl.userWhiteOrBlackChangeStatus,
                    params: {
                        id: datas.id,
						status: datas.status
                    }
                }).then((res) => {
                    this.$message.success('操作成功')
                    this.pageParam.freshCtrl++;
                }).catch((error) => {
                    this.tableData[index].status = this.tableData[index].status == 0 ? 1 : 0
                })
            }).catch(_=>{
                this.tableData[index].status = this.tableData[index].status == 0 ? 1 : 0
            })
        },

        operateRecord (datas) {
            this.$refs['blackOrWhite'].recordInfo = datas
            this.$refs['blackOrWhite'].getRecordList()
            this.$refs['blackOrWhite'].drawerVisible = true
        }
    },
}
</script>

<style scoped lang="scss">
    .s-btn-add{
        width: 105px;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
</style>
